// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import AppConfig from '../config/AppConfig'
// our "constructor"
import authStore from '../stores/AuthStore'
import { showError } from '../utilities/utils'
import Loading from '../utilities/loading'
import i18n from '../i18n/i18n'

const create = (baseURL = AppConfig.apiURL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    },
    // 20 second timeout...
    timeout: 20000,
  })
  const apiDownload = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
    },
    // 30 second timeout...
    timeout: 300000,
  })
  // api upload
  const apiUpload = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
    // 20 second timeout...
    timeout: 20000,
  })

  api.addRequestTransform(request => Loading.show())
  apiUpload.addRequestTransform(request => Loading.show())
  apiDownload.addRequestTransform(request => Loading.show())

  api.addResponseTransform(response => {
    if (!response.ok && response.data && response.data.code === 'PERMISSION_DENIED') {
      response.data.message = i18n.t('youDoNotHavePermissionToPerformThisOperation')
    }
    Loading.hide()
  })
  apiUpload.addResponseTransform(response => Loading.hide())
  apiDownload.addResponseTransform(response => Loading.hide())

  api.addMonitor(response => {
    process.env.NODE_ENV === 'development' &&
      (console.log(response.config.method, response.config.url) || console.log(response))
    if (
      process.env !== 'development' &&
      response.status === 401 &&
      !response.config.url.endsWith('/v1/account/login')
    ) {
      showError('Token expired!')
      // authStore.logout()
    }
  })
  apiUpload.addMonitor(
    response =>
      process.env.NODE_ENV === 'development' &&
      (console.log(response.config.method, response.config.url) || console.log(response)),
  )
  apiDownload.addMonitor(
    response =>
      process.env.NODE_ENV === 'development' &&
      (console.log(response.config.method, response.config.url) || console.log(response)),
  )

  const setToken = token => {
    api.setHeader('Authorization', 'Bearer ' + token)
    apiUpload.setHeader('Authorization', 'Bearer ' + token)
    apiDownload.setHeader('Authorization', 'Bearer ' + token)
  }

  const setLanguage = language => {
    api.setHeader('Accept-Language', language)
    apiUpload.setHeader('Accept-Language', language)
    apiDownload.setHeader('Accept-Language', language)
  }

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  // auth
  const login = data => api.post('/v1/account/login', data)
  const changePassword = data => api.post('/v1/account/change-password', data)
  const refreshToken = refreshToken =>
    api.post('/v1/account/refresh', { refresh_token: refreshToken })
  const resetPassword = email =>
    api.post('/v1/account/reset-password', { Email: email }, { headers: { 'Accept-Language': '' } })
  const setPassword = data => api.post('/v1/account/set-password', data)
  const register = data =>
    data.resetKey
      ? api.post('/v1/account/register-link', data)
      : api.post('/v1/account/register', data)

  const getUser = id => api.get(`/v1/user/${id}`)
  const getUsers = params => api.get('/v2/user/list', params)
  const getAdminOrganizations = params => api.get('/v2/user/orglist', params)
  const searchUsers = search => api.get('/v1/user/search', { q: search })
  const createUser = data => api.post('/v1/user/create-user', data)
  const updateUser = data => api.put('/v1/user', data)
  const deleteUser = id => api.delete(`/v1/user/${id}`)
  const getValidationLink = params => api.get('/v1/user/user-validation-link', params)

  const validateRegister = key => api.get(`/v2/account/validate-email/${key}`)
  const sendValidationEmail = data => api.post('/v1/account/activate-email', data)
  const changeLanguage = data => api.put('/v1/account/culture', data)

  const getOrganizations = query => api.get('/v1/organization', query)
  const getOrganization = id => api.get(`/v1/organization/${id}`)
  const createOrganization = data => api.post('/v1/organization', data)
  const updateOrganization = (id, data) => api.put(`/v1/organization/${id}`, data)
  const addOrganizationMember = (id, data) => api.post(`/v1/organization/${id}/members`, data)
  const removeOrganizationMember = (id, memberId) =>
    api.delete(`/v1/organization/${id}/members/${memberId}`)
  const updateOrganizationMember = (id, data) => api.put(`/v1/organization/${id}/members`, data)
  const deleteOrganization = id => api.delete(`/v1/organization/${id}`)
  const restoreOrganization = id => api.put(`/v1/organization/${id}/restore`)
  const changeOrganizationOwner = (id, toMemberId) =>
    api.put(`/v1/organization/${id}/change-owner`, { toMemberId })
  const acceptOrganizationOwner = (id, token) =>
    api.put(`/v1/organization/${id}/change-owner-request`, { token })
  const getChangingOwnerRequest = (id, token) =>
    api.get(`/v1/organization/${id}/change-owner-request`)
  const revokeOrganizationOwner = (id, token) =>
    api.put(`/v1/organization/${id}/change-owner-request-revoke`, { token })

  const getProjects = query => api.get('/v1/project/list', query)
  const getProjectFolders = query => api.get('/v1/projectfolder/list', query)
  const getProject = id => api.get(`/v1/project/${id}`)
  const createProject = data => api.post('/v1/project', data)
  const editProject = (id, data) => api.put(`/v1/project/${id}`, data)
  const moveProjectToTrash = id => api.post(`/v1/project/move-to-trash/${id}`)
  const deleteProject = id => api.delete(`/v1/project/${id}`)
  const deleteFolder = id => api.delete(`/v1/projectfolder/${id}`)
  const getProjectFolder = id => api.get(`/v1/projectfolder/${id}`)
  const createProjectFolder = data => api.post('/v1/ProjectFolder', data)
  const editProjectFolder = (id, data) => api.put(`/v1/ProjectFolder/${id}`, data)
  const moveFolderToTrash = id => api.post(`/v1/projectfolder/move-to-trash/${id}`)
  const restoreFolder = id => api.post(`/v1/projectfolder/restore/${id}`)
  const addProjectMember = (id, data) => api.post(`/v1/project/${id}/member`, { members: data })
  const getProjectMembers = id => api.get(`/v1/project/${id}/list-member`)
  const updateProjectMemberRole = (projectId, id, data) =>
    api.put(`/v1/project/${projectId}/member/${id}`, data)
  const createRegistrationLink = id => api.post(`/v1/project/${id}/member-link`)
  const joinProject = (id, params) => api.post(`/v1/project/${id}/join`, params)
  const acceptProjectMember = (projectId, id) =>
    api.post(`/v1/project/${projectId}/accept-pending-member/${id}`)
  const removeProjectMember = (projectId, id) => api.delete(`/v1/project/${projectId}/member/${id}`)
  const restoreProject = id => api.post(`/v1/project/restore/${id}`)
  const deletePhotoInSeries = (recordId, id) => api.delete(`/v1/record/${recordId}/series/${id}`)

  const getListRecord = query => api.get('/v1/record/find', query)
  const getRecord = id => api.get(`/v1/record/${id}`)
  const getShareRecord = (id, shareId) => api.get(`/v1/share/${shareId}/records/${id}`)
  const getListTrashRecord = query => api.get('/v1/record/list-in-trash', query)
  const editRecord = (id, data) => api.put(`/v1/record/${id}`, data)
  const moveRecordToTrash = id => api.post(`/v1/record/move-to-trash/${id}`)
  const restoreRecord = id => api.post(`/v1/record/restore/${id}`)
  const deleteRecord = id => api.delete(`/v1/record/${id}`)
  const moveRecord = (id, data) => api.post(`/v1/record/moving/${id}`, data)
  const copyRecord = (id, data) => api.post(`/v1/record/copy/${id}`, data)
  const updateRecord = (id, data) => api.put(`/v1/record/${id}`, data)

  const downloadFile = url => apiDownload.get(url, {}, { responseType: 'blob' })
  const downloadRecordMeta = (id, type, seriesId) =>
    apiDownload.get(`/v1/record/download-meta/${id}`, { type, seriesId }, { responseType: 'blob' })
  const downloadShareRecordMeta = (shareId, id, type, seriesId) =>
    apiDownload.get(
      `/v1/record/download-meta/${id}/${shareId}`,
      { type, seriesId },
      { responseType: 'blob' },
    )
  const downloadRecordMetaZip = (recordIds, type, immediateLink) =>
    apiDownload.get(
      '/v1/record/download-meta-zip',
      { type, recordIds, immediateLink },
      // { responseType: 'blob' },
    )
  const shareRecordMetaZip = (recordIds, data) =>
    api.post('/v1/record/share-meta-zip', { recordIds, ...data })
  const downloadRecord = (id, seriesId) =>
    apiDownload.get(`/v1/record/download/${id}`, { seriesId }, { responseType: 'blob' })
  const exportProject = (id, projectFolderId) =>
    apiDownload.get(
      `/v1/project/export/${id}`,
      projectFolderId ? { projectFolderId, type: 'xlsx' } : { type: 'xlsx' },
      { responseType: 'blob' },
    )
  const downloadProject = id => apiDownload.get(`/v1/project/download/${id}`)
  const downloadListUser = () =>
    apiDownload.get('/v1/user/export', { type: 'xlsx' }, { responseType: 'blob' })
  const rotate = (id, params) => api.get(`/v1/record/${id}/rotate`, params)
  const createRecord = (data, config) => api.post('/v1/record', data, config)
  const uploadRecordSeries = (id, data, config) =>
    apiUpload.post(`/v2/record/${id}/upload`, data, config)
  const uploadFile = (data, config) => apiUpload.post(`/v3/record/upload-file`, data, config)
  const uploadRecordMeta = (id, data) => api.post(`/v3/record/${id}/upload-meta`, data)
  const uploadCommentFile = (recordId, data, config) =>
    apiUpload.post(`/v1/record/${recordId}/comment/upload-file`, data, config)

  const emptyTrash = orgId => api.delete(`/v1/trash/${orgId}`)

  const createShareLink = data => api.post('/v1/share', data)
  const sendInvite = data => api.post('/v1/share/send-invite', data)
  const getShareLinks = query => api.get('/v1/share', query)
  const getShareLink = id => api.get(`/v1/share/${id}`)
  const deleteShareLink = id => api.delete(`/v1/share/${id}`)
  const getShareRecords = (id, params) => api.get(`/v1/share/${id}/records`, params)
  const getShareFolderRecords = (id, folderId, params) =>
    api.get(`/v1/share/${id}/folder/${folderId}/records`, params)
  const getShareVideoRecord = (id, token) => api.get(`/v1/share/shared-object/${id}/${token}`)
  const addComment = (id, data) => api.post(`/v1/record/${id}/comment`, data)
  const getComments = (id, query) => api.get(`/v1/record/${id}/comment`, query)
  const deleteComment = (recordId, id) => api.delete(`/v1/record/${recordId}/comment/${id}`)
  const updateComment = (recordId, id, data) =>
    api.put(`/v1/record/${recordId}/comment/${id}`, data)

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2setToken,
    setToken,
    setLanguage,
    // auth
    login,
    changePassword,
    refreshToken,
    resetPassword,
    setPassword,
    register,
    getUser,
    getUsers,
    searchUsers,
    createUser,
    updateUser,
    deleteUser,
    getValidationLink,
    getAdminOrganizations,
    validateRegister,
    sendValidationEmail,
    changeLanguage,

    getOrganizations,
    getOrganization,
    createOrganization,
    updateOrganization,
    addOrganizationMember,
    removeOrganizationMember,
    updateOrganizationMember,
    deleteOrganization,
    restoreOrganization,
    changeOrganizationOwner,
    acceptOrganizationOwner,
    revokeOrganizationOwner,
    getChangingOwnerRequest,

    getProjects,
    getProjectFolders,
    getProject,
    createProject,
    editProject,
    moveProjectToTrash,
    getProjectMembers,
    deleteProject,
    deleteFolder,
    getProjectFolder,
    createProjectFolder,
    editProjectFolder,
    addProjectMember,
    updateProjectMemberRole,
    createRegistrationLink,
    joinProject,
    acceptProjectMember,
    removeProjectMember,
    restoreProject,
    moveFolderToTrash,
    restoreFolder,
    exportProject,

    getListTrashRecord,
    getListRecord,
    getRecord,
    getShareRecord,
    editRecord,
    moveRecordToTrash,
    restoreRecord,
    deleteRecord,
    downloadRecord,
    downloadRecordMeta,
    downloadShareRecordMeta,
    downloadRecordMetaZip,
    shareRecordMetaZip,
    moveRecord,
    copyRecord,
    updateRecord,

    downloadListUser,
    downloadProject,
    rotate,
    createRecord,
    uploadRecordSeries,
    uploadFile,
    uploadRecordMeta,
    deletePhotoInSeries,
    uploadCommentFile,

    emptyTrash,
    downloadFile,
    createShareLink,
    sendInvite,
    getShareLinks,
    getShareLink,
    deleteShareLink,
    getShareRecords,
    getShareFolderRecords,
    getShareVideoRecord,

    addComment,
    getComments,
    deleteComment,
    updateComment,
  }
}

// let's return back our create method as the default.
export default {
  create,
}
